// 导出页面为PDF格式
import domtoimage from '@/assets/js/dom-to-image/src/dom-to-image'
import JsPDF from 'jspdf'
export default {
  install(Vue, options) {
    Vue.prototype.$getPdf = function(htmlTitle) {
      // var htmlTitle = this.htmlTitle
      var id = this.id
      var shareContent = document.getElementById(id)
      var contentWidth = shareContent.offsetWidth
      var contentHeight = shareContent.offsetHeight

      domtoimage.toJpeg(shareContent, {
        width:contentWidth, // 高度宽度自行设定
        height:contentHeight
      }).then(function(dataUrl) {
        console.log(dataUrl)

        // var type = 'jpeg'
        // var imgData = dataUrl
        // debugger
        // console.log(imgData)
        // var _fixType = function(type) {
        //   type = type.toLowerCase().replace(/jpg/i, 'jpeg')
        //   var r = type.match(/png|jpeg|bmp|gif/)[0]
        //   return 'image/' + r
        // }
        // // 加工image data，替换mime type
        // imgData = imgData.replace(_fixType(type), 'image/octet-stream')
        // /**
        //  * 在本地进行文件保存
        //  * @param  {String} data     要保存到本地的图片数据
        //  * @param  {String} filename 文件名
        //  */
        // var saveFile = function(data, filename) {
        //   var save_link = document.createElementNS('http://www.w3.org/1999/xhtml', 'a')
        //   save_link.href = data
        //   save_link.download = filename
        //   var event = document.createEvent('MouseEvents')
        //   event.initMouseEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null)
        //   save_link.dispatchEvent(event)
        // }
        // // 下载后的文件名
        // var filename = '成绩单' + (new Date()).getTime() + '.' + type
        // // download
        // saveFile(imgData, filename)

        // debugger
        var pdfX = contentWidth / 2 * 0.75
        var pdfY = contentHeight / 2 * 0.75 // 500为底部留白

        // 设置内容图片的尺寸，img是pt单位
        var imgX = (contentWidth / 2 * 0.75)
        var imgY = (contentHeight / 2 * 0.75) // 内容图片这里不需要留白的距离

        // 初始化jspdf 第一个参数方向：默认''时为纵向，第二个参数设置pdf内容图片使用的长度单位为pt，第三个参数为PDF的大小，单位是pt
        var PDF = new JsPDF('', 'pt', [pdfX, pdfY])

        // 将内容图片添加到pdf中，因为内容宽高和pdf宽高一样，就只需要一页，位置就是 0,0
        PDF.addImage(dataUrl, 'jpeg', 0, 0, imgX, imgY)
        PDF.save(htmlTitle+'.pdf')
      })
    }
  }

}
