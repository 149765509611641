import Vue from "vue";
import axios from "axios";
import { Dialog, Toast } from "vant";
import config from "../config/index.js";
Vue.prototype.$axios = axios;
axios.defaults.timeout = 600000;
import router from "../router";

axios.defaults.baseURL = config.baseUrl;
// if (process.env.NODE_ENV !== 'development') {
// 开发环境下的代理地址，解决本地跨域跨域，配置在config目录下的index.js dev.proxyTable中
axios.defaults.baseURL = "https://jfys.chinawans.com"; //正式
// } else {
//   axios.defaults.baseURL = 'http://192.168.20.230:12001'  //测试
// }

axios.interceptors.request.use(
  (config) => {
    let token = localStorage.getItem("token");
    let tokenHeader = localStorage.getItem("tokenHeader");
    if (config.url != "/api/wx/sdk/config") {
      if (token) {
        config.headers[tokenHeader] = String(token);
      }

      if (
        router.app.$route.path == "/pc/acceptanceDetail" ||
        router.app.$route.path == "/pc/acceptanceList"
      ) {
        let zdmj = localStorage.getItem("zdmj");
        if (zdmj) {
          config.headers["Authorization"] = JSON.parse(zdmj).Authorization;
        }
      }
    }

    return config;
  },
  (err) => {
    return Promise.reject(err);
  }
);

axios.interceptors.response.use(
  (response) => {
    //成功请求到数据
    // //这里根据后端提供的数据进行对应的处理

    if (response.data.code == 200) {
      return response;
    } else if (response.data.code == 500) {
      Dialog.alert({
        title: "提示",
        message: response.data.message,
      });
      return Promise.reject(response);
    } else if (response.data.code == 400) {
      Dialog.alert({
        title: "提示",
        message: response.data.message,
      });
      return Promise.reject(response);
    } else if (response.data.code == 401) {
      if (router.app.$route.path.indexOf("/pc/") != -1) {
        router.app.$router.replace({ path: "/pc/overdue" });
      } else {
        router.app.$router.replace({ path: "/login" });
      }
      return Promise.reject(response);
    } else {
      return Promise.reject(response);
    }
  },
  (error) => {
    //响应错误处理
    Toast.clear();
    Dialog.alert({
      title: "提示",
      message: "请求超时",
    });
    return Promise.reject(error);
  }
);
