<template>
  <div id="app">
    <keep-alive :include="$store.state.include"> 
      <!-- -->
      <router-view  />
    </keep-alive>
    <div id="goTop" class="goTop" v-show="goTopShow" @click="goTop">
      <div class="icon-goTop flex" ></div>
    </div>
  </div>
</template>
<script>
export default {
  props: {},
  data() {
    return {
      scrollTop: '',
      goTopShow:false,
    };
  },
  methods: {
    handleScroll () {
        this.scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
        if(this.scrollTop>500){
          　this.goTopShow=true
        } else {
           this.goTopShow=false
        }
    },
    goTop(){
        let timer=null,_that=this;
        cancelAnimationFrame(timer)
        timer=requestAnimationFrame(function fn(){
          if(_that.scrollTop>0){
              _that.scrollTop-=500;
              document.body.scrollTop = document.documentElement.scrollTop = _that.scrollTop;
              timer=requestAnimationFrame(fn)
          }else {
            　cancelAnimationFrame(timer);
            　_that.goTopShow=false;
          }
        })
    }
  },
  computed: {

  },
  created() {
    this.$wxConfig();
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  }
};
</script>
<style scoped>
.goTop{
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 10px;
    bottom: 100px;
    width: 40px;
    height: 40px;
    background-color: #fff;
    border-radius: 50%;
    cursor: pointer;
    box-shadow: rgb(0 0 0 / 12%) 0px 0px 6px;
}
.goTop .icon-goTop{
    width: 0;
    height: 0;
    border-bottom: 15px solid rgb(25, 137, 250);
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
}
</style>
<style lang="scss">
#app {
  color: #333;
}
#app img{
   pointer-events:none;
}
.footer-btn {
 
  position: fixed;
  z-index: 1;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 18px;
  background: linear-gradient(to bottom, #7fa9ff, #6497ff);
  color: #fff;
  font-family: PingFang SC;
  font-size: 16px;
  font-weight: bold;
  line-height: 1;
  text-align: center;
  box-shadow: 2px 3px 11px 0 rgba(255, 255, 255, 0.26) inset,
    -1px -1px 9px 0 rgba(90, 140, 243, 0.67) inset;
  box-sizing: border-box;
}
.submit-btn {
  margin-top: 85px;
  background: linear-gradient(to bottom, #7fa9ff, #6497ff);
  box-shadow: 2px 3px 11px 0px rgba(255, 255, 255, 0.26) inset,
    -1px -1px 9px 0px rgba(90, 140, 243, 0.67) inset;
  border-radius: 5px;
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #ffffff;
  box-sizing: border-box;
  padding: 12px;
  text-align: center;
  letter-spacing: 20px;
  line-height: 1;
  text-indent: 20px;
}
.theme-color {
  color: #6396fe;
}
.content-title {
  position: relative;
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: bold;
  padding-left: 12px;
  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    height: 14px;
    width: 3px;
    background: #6497ff;
    border-radius: 2px;
    margin-top: -7px;
  }
}
.score-img {
  width: calc((100% - 16px) / 3);
  border-radius: 8px;
  margin-right: 8px;
  min-width: 113px;
  min-height: 113px;
  max-height: 113px;
  &:nth-child(3n) {
    margin-right: 0;
  }
}
.score-text {
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #ffa21e;
}
.page-wrap{
  min-height:100vh;
  background-color:#f6f6f6;
}
.screen-wrap {
  display: flex;
  align-items: center;
  min-width: calc((100% - 24px) / 2);
  max-width: calc((100% - 24px) / 2);
  padding: 6px 10px;
  border: 1px solid rgba(#000, 0.2);
  color: #666;
  font-size: 14px;
  line-height: 1;
  border-radius: 4px;
  box-sizing: border-box;
  & + .screen-wrap {
    margin-left: 12px;
  }
}
.tab-text {
    position: relative;
    padding: 14px 30px;
    color: #666;
    font-size: 14px;
    font-weight: 400;
    line-height: 1;
    &:before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 3px;
        border-radius: 2px;
    }
}

.tab-active {
    .tab-text {
        color: #6396fe;
        &:before {
            background-color: #6396fe;
        }
    }
}
.icon-xs{
  width:24px;
  height:24px;
}
</style>
