import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)
function getAbsolutePath () {

  // let path = location.pathname
 
  // return path.substring(0, path.lastIndexOf('/') + 1)
  return 'test'
 }
const routes = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path:'/login',
    meta: {},
    component:() => import('views/login.vue')
  },
  {
    path:'/resetPassword',
    meta: {},
    component:() => import('views/resetPassword.vue')
  },
  {
    path:'/home',
    name:'Home',
    meta: {},
    component:() => import('views/Home.vue')
  },
  {
    path:'/region',
    name:'Region',
    meta: {},
    redirect:{ name:'RegionList' }
  },
  {
    path:'/region/list',
    name:'RegionList',
    meta: {},
    component:() => import('views/region/list.vue')
  },
  {
    path:'/region/edit',
    name:'RegionEdit',
    meta: {},
    component:() => import('views/region/edit.vue')
  },
  {
    path:'/acceptance',
    name:'Acceptance',
    meta: {keepAlive: true},
    component:() => import('views/acceptance/index.vue')
  },
  {
    path:'/acceptance/list',
    name:'',
    meta: {},
    component:() => import('views/acceptance/list/index.vue')
  },
  {
    path:'/acceptance/list/details',
    meta: {},
    component:() => import('views/acceptance/list/details/index.vue')
  },
  {
    path:'/acceptance/list/details/preview',
    meta: {},
    component:() => import('views/acceptance/list/details/preview/index.vue')
  },
  {
    path:'/release',
    name:'release',
    meta: {keepAlive: true},
    component:() => import('views/release/index.vue')
  },
  {
    path:'/release/list',
    name:'',
    meta: {},
    component:() => import('views/release/list/index.vue')
  },
  {
    path:'/release/list/details',
    meta: {},
    component:() => import('views/release/list/details/index.vue')
  },
  {
    path:'/acceptanceResults',
    name:'acceptanceResults',
    meta: {keepAlive: true},
    component:() => import('views/acceptanceResults/index.vue')
  },
  {
    path:'/acceptanceResults/list',
    name:'',
    meta: {},
    component:() => import('views/acceptanceResults/list/index.vue')
  },
  {
    path:'/acceptanceResults/list/details',
    meta: {},
    component:() => import('views/acceptanceResults/list/details/index.vue')
  },
  {
    path:'/previewAcceptance',
    meta: {},
    component:() => import('views/previewAcceptance/index.vue')
  },
  {
    path:'/previewLogin',
    meta: {},
    component:() => import('views/previewAcceptance/login.vue')
  },
  {
    path:'/previewAcceptance/fail',
    meta: {},
    component:() => import('views/previewAcceptance/fail/index.vue')
  },
  {
    path:'/regulate/district',
    name:'district',
    meta: {},
    component:() => import('views/regulate/district/list.vue')
  },
  {
    path:'/regulate/district/detail',
    name:'districtDetail',
    meta: {},
    component:() => import('views/regulate/district/detail.vue')
  },
  {
    path:'/regulate/project',
    meta: {},
    component:() => import('views/regulate/project/list.vue')
  },
  {
    path:'/regulate/project/detail',
    name:'projectDetail',
    meta: {},
    component:() => import('views/regulate/project/detail.vue')
  },
  {
    path:'/regulate/project/add',
    name:'projectAdd',
    meta: {},
    component:() => import('views/regulate/project/detail.vue')
  },
  {
    path:'/regulate/project/people',
    name:'people',
    meta: {},
    component:() => import('views/regulate/project/people.vue')
  },
  {
    path:'/regulate/project/group',
    name:'group',
    meta: {},
    component:() => import('views/regulate/project/group.vue')
  },
  {
    path:'/regulate/project/supplier',
    name:'supplier',
    meta: {},
    component:() => import('views/regulate/project/supplier.vue')
  },
  {
    path:'/regulate/city',
    meta: {title: '城市管理'},
    component:() => import('views/regulate/city/list.vue')
  },
  {
    path:'/regulate/city/detail',
    meta: {title: '城市管理'},
    component:() => import('views/regulate/city/detail.vue')
  },
  {
    path:'/report/:id',
    name:'report',
    meta: {title: '报告'},
    component:() => import('views/report/index.vue')
  },
  {
    path:'/pc/acceptanceList',
    name:'acceptanceList',
    meta: {title: 'pc'},
    component:() => import('views/pc/acceptanceList.vue')
  },
  {
    path:'/pc/acceptanceDetail',
    name:'acceptanceDetail',
    meta: {title: 'pc'},
    component:() => import('views/pc/acceptanceDetail.vue')
  },
  {
    path:'/pc/overdue',
    name:'overdue',
    meta: {title: 'pc'},
    component:() => import('views/pc/overdue.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  // base: getAbsolutePath(),
  routes
})

export default router
