import axios from 'axios'
import qs from 'qs'
function getParameter(data) {
  var parameter = '?'
  for (var item in data) {
    parameter = parameter + item + '=' + data[item] + '&'
  }
  parameter = parameter.substring(0, parameter.length - 1)
  return parameter
}
// 用户登入
export function login(data) {
  return axios({
    url:`/api/login/normal`,
    method:'post',
    data:qs.stringify(data)
  })
}

// 修改密码   
export function editPassword(data) {
  return axios({
    url:`/api/admin/editPassword`,
    method:'put',
    data:data
  })
}

// 是否有删除权限
export function authority() {
  return axios({
    url:`/api/admin/authority`,
    method:'get'
  })
}


// 区域列表
export function areaList(data) {
  return axios({
    url:`/api/area/list${getParameter(data)}`,
    method:'get'
  })
}

// 区域列表(v+)
export function releaseAreaList(data) {
  return axios({
    url:`/api/release/area/list${getParameter(data)}`,
    method:'get'
  })
}

// 项目验收-城市列表
export function cityList(data) {
  return axios({
    url:`/api/city/list${getParameter(data)}`,
    method:'get'
  })
}

// 项目验收-城市列表(v+)
export function releaseCityList(data) {
  return axios({
    url:`/api/release/city/list${getParameter(data)}`,
    method:'get'
  })
}

// 项目验收-项目列表
export function projectList(data) {
  return axios({
    url:`/api/project/list`,
    method:'post',
    data:qs.stringify(data)
  })
}

// 项目验收-项目列表(V+)
export function projectReleaseList(data) {
  return axios({
    url:`/api/release/project/list`,
    method:'post',
    data:qs.stringify(data)
  })
}

// 项目验收-表
export function specialTables(data) {
  return axios({
    url:`/api/special/tables${getParameter(data)}`,
    method:'get'
  })
}

// 项目验收-表(V+)
export function releaseTables(data) {
  return axios({
    url:`/api/release/tables${getParameter(data)}`,
    method:'get'
  })
}

// 项目验收-样板房列表
export function housesList(data) {
  return axios({
    url:`/api/houses/list${getParameter(data)}`,
    method:'get'
  })
}

// 项目验收-样板房列表(V+)
export function releaseHousesList(data) {
  return axios({
    url:`/api/release/houses/list${getParameter(data)}`,
    method:'get'
  })
}

// 验收评分主表详情
export function acceptanceDetails(data) {
  return axios({
    url:`/api/acceptance/details`,
    method:'post',
    data:qs.stringify(data)
  })
}

// 验收评分主表详情(V+)
export function releaseAcceptanceDetails(data) {
  return axios({
    url:`/api/release/acceptance/details`,
    method:'post',
    data:qs.stringify(data)
  })
}

// 新增验收评分主表
export function acceptance(data) {
  return axios({
    url:`/api/acceptance`,
    method:'post',
    data:data
  })
}

// 修改验收评分主表
export function acceptancePut(id, data) {
  return axios({
    url:`/api/acceptance/${id}`,
    method:'put',
    data:data
  })
}

// 修改验收表状态
export function acceptancePutState(id,state) {
  return axios({
    url:`/api/acceptance/${id}/state/${state}`,
    method:'put'
  })
}
// Oss上传签名生成
export function ossPolicy() {
  return axios({
    url:`/api/aliyun/oss/policy`,
    method:'get'
  })
}

// 生成临时链接
export function shareLinks(id) {
  return axios({
    url:`/api/acceptance/${id}/share/links`,
    method:'get'
  })
}

// 临时链接获取验收表详情
export function shareDetails(key) {
  return axios({
    url:`/api/acceptance/share/details/${key}`,
    method:'get'
  })
}

// js-sdk授权
export function config(data) {
  return axios({
    url:`/api/wx/sdk/config`,
    method:'post',
    data:qs.stringify(data)
  })
}

// 新增基础区域表
export function addArea(data) {
  return axios({
    url:`/api/area`,
    method:'post',
    data:data
  })
}

// 修改基础区域表
export function putArea(id, data) {
  return axios({
    url:`/api/area/${id}`,
    method:'put',
    data:data
  })
}
// 逻辑删除基础区域表
export function delArea(id) {
  return axios({
    url:`/api/area/${id}`,
    method:'DELETE'
  })
}
// 新增基础城市表
export function addcity(data) {
  return axios({
    url:`/api/city`,
    method:'post',
    data:data
  })
}
// 修改基础城市表
export function putCity(id, data) {
  return axios({
    url:`/api/city/${id}`,
    method:'put',
    data:data
  })
}
// 逻辑删除基础城市表
export function delCity(id) {
  return axios({
    url:`/api/city/${id}`,
    method:'DELETE'
  })
}
// 新增基础项目表
export function addProject(data) {
  return axios({
    url:`/api/project`,
    method:'post',
    data:data
  })
}

// 修改基础项目表
export function changeProject(id, data) {
  return axios({
    url:`/api/project/${id}`,
    method:'put',
    data:data
  })
}

// 基础项目表详情
export function getProject(id) {
  return axios({
    url:`/api/project/details`,
    method:'get',
    params: {id: id}
  })
}

// 详情
export function detailsInfo(data) {
  return axios({
    url:`/api/project/info/details${getParameter(data)}`,
    method:'get',
  })
}

// 详情
export function projectInfo(data) {
  console.log(data);
  return axios({
    url:`/api/project/info`,
    method:'post',
    data: [data]
  })
}
// 删除
export function projectDel(id) {
  return axios({
    url:`/api/project/${id}`,
    method:'DELETE'
  })
}
//获取临时素材图片上传 
export function wxMedia(data) {
  return axios({
    url:`/api/wx/media`,
    method:'post',
    data:qs.stringify(data)
  })
}

//登录时效性校验
export function validity(data) {
  return axios({
    url:`/api/login/validity`,
    method:'post',
    data:qs.stringify(data)
  })
}

// 详情
export function getReport(id) {
  return axios({
    url:`/api/acceptance/report/${id}`,
    method:'get',
  })
}

// 是否可编辑验收详情
export function postIsEdit(data) {
  return axios({
    url:`/api/release/isEdit`,
    method:'post',
    data:qs.stringify(data)
  })
}

//上传oss
export function ossUpload(param) {
  return axios({
    url:`/api/aliyun/oss/upload`,
    method:'post',
    data:param,
    headers: {'Content-Type': 'multipart/form-data'}
  })
}

//登录
export function wxlogin(data) {
  return axios({
      url: `/api/users/login`,
      method: 'post',
      data: qs.stringify(data)
  })
}