var baseUrl = "";
var socketUrl = "";
if (process.env.NODE_ENV !== "development") {
  //开发环境下的代理地址，解决本地跨域跨域，配置在config目录下的index.js dev.proxyTable中
  baseUrl = "https://jfys.chinawans.com/"; //正式
  socketUrl = "wss://jfys.chinawans.com/"; //正式
  // baseUrl = 'http://jfys.vaiwan.com/'
  // socketUrl = 'ws://jfys.vaiwan.com/'
} else {
  baseUrl = "https://jfys.chinawans.com/"; //正式
  socketUrl = "wss://jfys.chinawans.com/"; //正式
  // baseUrl = 'http://jfys.vaiwan.com/'
  // socketUrl = 'ws://jfys.vaiwan.com/'
}

export default { baseUrl, socketUrl };
