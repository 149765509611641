import router from "../router"
// import store from '../store';

//路由验证
router.beforeEach((to, from, next) => {
  if (from.meta.keepAlive) {
    var str = from.path + 'ScrollTop'
    var scrollTop = document.body.scrollTop = document.documentElement.scrollTop
    sessionStorage.setItem(str, scrollTop)
  } else {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }
  
  next();
});
router.afterEach((to, from, next) => {
  var str = to.path + 'ScrollTop'
  if (sessionStorage.getItem(str)) {
    var scrollTop = sessionStorage.getItem(str)
    
    setTimeout(()=>{
      document.body.scrollTop = document.documentElement.scrollTop = scrollTop 
    },100)
  }
  
  
})