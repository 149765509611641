import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "tailwindcss/tailwind.css";
import './axios'
import './axios/router'
import ClipboardJS from 'clipboard';
import wxConfig from './assets/js/wxConfig.js'
import '@vant/touch-emulator';
import htmlToPdf from '@/assets/js/htmlToPdf'

Vue.use(htmlToPdf)
// import VueSocketIO from 'vue-socket.io'
// import ElementUI from 'element-ui'; 
// import 'element-ui/lib/theme-chalk/index.css';

// Vue.use(ElementUI);

// Vue.use(new VueSocketIO({
//   // 控制台调试 console.log
//   debug: true,
//   // 连接地址 后端提供
//   connection: 'http://localhost:3000',
  
// }))
Vue.use(wxConfig) 
//引入vconsole
if(process.env.NODE_ENV !== 'production'){
  require('./assets/js/vconsole')
}
Vue.config.productionTip = false;
Vue.prototype.$copyText = function (text, container) {
  return new Promise(function (resolve, reject) {
    var fakeElement = document.createElement('button')
    var clipboard = new ClipboardJS(fakeElement, {
      text: function () { return text },
      action: function () { return 'copy' },
      container: typeof container === 'object' ? container : document.body
    })
    clipboard.on('success', function (e) {
      clipboard.destroy()
      Vue.prototype.$toast.success('复制成功');
      resolve(e)
    })
    clipboard.on('error', function (e) {
      clipboard.destroy()
      reject(e)
    })
    document.body.appendChild(fakeElement);
    fakeElement.click();
    // document.body.removeChild(fakeElement);
  })
}
// 加法计算
Vue.prototype.NumberAdd = function(arg1, arg2) {
  var r1, r2, m, n;
  try {
      r1 = arg1.toString().split(".")[1].length
  } catch (e) {
      r1 = 0
  }
  try {
      r2 = arg2.toString().split(".")[1].length
  } catch (e) { 
      r2 = 0 
  } 
  m = Math.pow(10, Math.max(r1, r2))
  n = (r1 >= r2) ? r1 : r2;
  return Number(((arg1 * m + arg2 * m) / m).toFixed(n));
}

// 减法计算
Vue.prototype.NumberSub = function(arg1, arg2) {
  var re1, re2, m, n;
  try {
      re1 = arg1.toString().split(".")[1].length;
  } catch (e) {
  re1 = 0;
  }
  try {
      re2 = arg2.toString().split(".")[1].length;
  } catch (e) {
      re2 = 0;
  }
  m = Math.pow(10, Math.max(re1, re2)); 
  n = (re1 >= re2) ? re1 : re2;
  return Number(((arg1 * m - arg2 * m) / m).toFixed(n));
}

 // 乘法计算
 Vue.prototype.NumberMul = function(arg1, arg2) {
  var m = 0;
  var s1 = arg1.toString();
  var s2 = arg2.toString();
  try {
      m += s1.split(".")[1].length;
  } catch (e) {}
  try {
      m += s2.split(".")[1].length;
  } catch (e) {}

  return Number(s1.replace(".", "")) * Number(s2.replace(".", "")) / Math.pow(10, m);
}
//除数，被除数， 保留的小数点后的位数
Vue.prototype.NumberDiv = function (arg1,arg2,digit){
  var t1=0,t2=0,r1,r2;
  
  try{t1=arg1.toString().split(".")[1].length}catch(e){}
  try{t2=arg2.toString().split(".")[1].length}catch(e){}
  r1=Number(arg1.toString().replace(".",""))
  r2=Number(arg2.toString().replace(".",""))
  //获取小数点后的计算值
 var result= ((r1/r2)*Math.pow(10,t2-t1)).toString()
  var str = result.split(".")
  var result2=str.length==1?'':str[1];
  result2=result2.substring(0,digit>result2.length?result2.length:digit);

  return Number(result.split(".")[0]+"."+result2);
}

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
